import Image from 'next/image';

import ArrowRight from '../images/arrow-right.svg';
import Patna from '../images/patna.svg';
import DelhiNCR from '../images/delhi_ncr.svg';
import Bangalore from '../images/bangalore.svg';
import Mumbai from '../images/mumbai.svg';
import Hyderabad from '../images/hyderabad.svg';
import Chennai from '../images/chennai.svg';
import Kolkata from '../images/kolkata.svg';
import International from '../images/international.svg';
import Coding from '../images/coding.svg';
import Engineering from '../images/engineering.svg';
import Medical from '../images/medical.svg';
import MBA from '../images/mba.svg';
import Design from '../images/design.svg';
import Science from '../images/science.svg';
import Media from '../images/media.svg';
import Humanities from '../images/humanities.svg';
import type {NextComponentType} from 'next';
const Campuses: NextComponentType = () => {
	return (
		<>
			<section className='pt-[50px] pb-[50px] sm:pt-[80px] sm:pb-[80px] lg:pt-[100px] lg:pb-[100px]'>
				<div className='innerDiv mx-auto'>
					<div className='sm:flex justify-between items-center py-4 sm:p-0 mb-12'>
						<div>
							<h2 className='text-3xl font-bold'>Universities & Colleges</h2>
							<p className='text-lg my-2'>Find from 20,000+ campuses around India and abroad</p>
						</div>
						<div>
							<a href='#' className='text-lg align-middle inline-block'>
								View all campus <Image alt='' className='arrowRight' src={ArrowRight} width={25} height={25} />
							</a>
						</div>
					</div>
					<h3 className='text-2xl pb-4'>Popular cities</h3>
					<div className='grid lg:grid-cols-8 sm:grid-cols-3 grid-cols-2 gap-8 mb-12'>
						<div className='text-center'>
							<Image src={Patna} alt='' width={132} height={78} />
							<span className='text-sm block'>Patna</span>
						</div>
						<div className='text-center'>
							<Image src={DelhiNCR} alt='' width={132} height={78} />
							<span className='text-sm block'>Delhi/NCR</span>
						</div>
						<div className='text-center'>
							<Image src={Bangalore} alt='' width={132} height={78} />
							<span className='text-sm block'>Bangalore</span>
						</div>
						<div className='text-center'>
							<Image src={Mumbai} alt='' width={132} height={78} />
							<span className='text-sm block'>Mumbai</span>
						</div>
						<div className='text-center'>
							<Image src={Hyderabad} alt='' width={132} height={78} />
							<span className='text-sm block'>Hyderabad</span>
						</div>
						<div className='text-center'>
							<Image src={Chennai} alt='' width={132} height={78} />
							<span className='text-sm block'>Chennai</span>
						</div>
						<div className='text-center'>
							<Image src={Kolkata} alt='' width={132} height={78} />
							<span className='text-sm block'>Kolkata</span>
						</div>
						<div className='text-center'>
							<Image src={International} alt='' width={132} height={78} />
							<span className='text-sm block'>International</span>
						</div>
					</div>
					<h3 className='text-2xl pb-4'>Popular categories</h3>
					<div className='grid lg:grid-cols-8 sm:grid-cols-3 grid-cols-2 gap-8'>
						<div className='text-center'>
							<Image src={Coding} alt='' width={132} height={78} />
							<span className='text-sm block'>Coding</span>
						</div>
						<div className='text-center'>
							<Image src={Engineering} alt='' width={132} height={78} />
							<span className='text-sm block'>Engineering</span>
						</div>

						<div className='text-center'>
							<Image src={Medical} alt='' width={132} height={78} />
							<span className='text-sm block'>Medical</span>
						</div>
						<div className='text-center'>
							<Image src={MBA} alt='' width={132} height={78} />
							<span className='text-sm block'>MBA</span>
						</div>
						<div className='text-center'>
							<Image src={Design} alt='' width={132} height={78} />
							<span className='text-sm block'>Design</span>
						</div>
						<div className='text-center'>
							<Image src={Science} alt='' width={132} height={78} />
							<span className='text-sm block'>Science</span>
						</div>
						<div className='text-center'>
							<Image src={Media} alt='' width={132} height={78} />
							<span className='text-sm block'>Media</span>
						</div>
						<div className='text-center'>
							<Image src={Humanities} alt='' width={132} height={78} />
							<span className='text-sm block'>Humanities</span>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};
export default Campuses;
