import type {NextPage} from 'next';
import Head from 'next/head';

import HeroSection from '../components/HeroSection';
import CampusSearch from '../components/CampusSearch';
import SchoolSearch from '../components/SchoolSearch';
import Subject from '../components/Subject';
const Home: NextPage = () => {
	return (
		<>
			<Head>
			<title>Campus | In your own space and time</title>
			</Head>
			<HeroSection />
			<CampusSearch />
			<SchoolSearch />
			<Subject />
		</>
	);
}
export default Home;