import Image from 'next/image';
import {useState} from 'react';
import type {NextComponentType} from 'next';
import Book from '../images/book.svg';
import campus from '../images/campus.webp';
const HeroSection: NextComponentType = () => {
	const [showDropdown, setShowDropdown] = useState(false);
	const toggleClass = () => {
		setShowDropdown(!showDropdown);
	};
	return (
		<>
			<section className='pt-[50px] pb-[50px] sm:pt-[80px] sm:pb-[80px] lg:pt-[100px] lg:pb-[100px]'>
				<div className='innerDiv mx-auto'>
					<div className='grid lg:grid-cols-2 lg:gap-40 gap-10'>
						<div>
							<h3 className='lg:text-6xl xs:text-4xl font-bold'>
								Discover <br></br> your<span className='text-color-2'> campus</span>
							</h3>
							<p className='text-xl py-10'>Welcome to your Smart, fun and personalised Community.</p>
							<div className={`${showDropdown ? 'relative rounded-md bg-black mobileSearch' : 'relative bg-black rounded-md'}`}>
								<div className='mobile-search-icons hidden' onClick={toggleClass}>
									<i className='fas fa-arrow-left text-white'></i>
									<a href='#' className='text-primary'>
										Search
									</a>
								</div>
								<div className='absolute top-6 left-3 searchIcon'>
									<Image src={Book} alt='' width={25} height={25} />
								</div>
								<span className={`${showDropdown ? 'text-white bg-black w-full rounded-md py-6 pl-12 cursor-pointer hidden' : 'text-white block bg-black w-full rounded-md py-6 pl-12 cursor-pointer'}`} onClick={toggleClass}>
									Looking for a match?
								</span>
								<input
									type='text'
									className={`${
										showDropdown
											? 'placeholder:text-white text-white block bg-transparent md:rounded-l-md py-6 md:pl-12 pl-6 cursor-pointer md:w-1/2 w-full focus:outline-0 md:border-r md:border-r-gray-500 border border-gray-500'
											: 'hidden placeholder:text-white text-white bg-transparent md:rounded-l-md py-6 md:pl-12 pl-6 cursor-pointer md:w-1/2 w-full focus:outline-0 md:border-r md:border-r-gray-500 border border-gray-500'
									}`}
									placeholder='Select State'
								/>
								<ul className={`${showDropdown ? 'md:absolute top-full mt-1 bg-black rounded-md z-50 w-1/3 text-white p-4' : 'hidden md:absolute top-full mt-1 bg-black rounded-md z-50 w-1/3 text-white p-4'}`}>
									<li className='mb-2'>
										<a href='#'>Delhi</a>
									</li>
									<li>
										<a href='#'>Rajasthan</a>
									</li>
								</ul>
								<div className={`${showDropdown ? 'md:absolute top-0 right-0 md:w-1/2 w-full' : 'hidden md:absolute top-0 right-0 md:w-1/2 w-full'}`}>
									<input type='text' className='placeholder:text-white py-6 bg-transparent text-white px-4 focus:outline-0 md:border-0 border border-gray-500 w-full' placeholder='Search Keyword' />
								</div>
								<button type='submit' className='bg-primary inline-block px-6 py-2 rounded-md absolute top-3 right-3 searchBtn'>
									<span className='text-2xl'>
										<i className='fas fa-magnifying-glass'></i>
									</span>
								</button>
							</div>
						</div>
						<div className='text-center lg:pt-0 xs:pt-8'>
							<Image src={campus} alt='' className='img-fluid' width={451} height={391} />
						</div>
					</div>
				</div>
			</section>
		</>
	);
};
export default HeroSection;
