import Image from 'next/image';
import type {NextComponentType} from 'next';
const SchoolSearch: NextComponentType = () => {
	return (
		<>
			<section className='bg-primary pt-[50px] pb-[50px] sm:pt-[80px] sm:pb-[80px] lg:pt-[100px] lg:pb-[100px]'>
				<div className='innerDiv mx-auto'>
					<div className='text-center flex justify-center item-center'>
						<div className='mt-14'>
							<h2 className='text-4xl font-bold py-4'>Not sure where to school? Perfect.</h2>
							<p className='md:text-2xl mb-6'>With more than 50000+ hyperlocal, national and global schools listed on Gurucool, we help you find your match.</p>
							<a href='#' className='py-2 px-12  bg-black mt-4 text-white inline-block'>
								Let's go
							</a>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};
export default SchoolSearch;
