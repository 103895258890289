import Image from 'next/image';
import Router from 'next/router';
import SubjectImage from "../images/subject-1.jpg"
import React, {useState, useEffect} from 'react';
const Subject = () => {
	const [scrollY, setScrollY] = useState(0);

	function logit() {
		setScrollY(window.pageYOffset);
	}

	useEffect(() => {
		function watchScroll() {
			window.addEventListener('scroll', logit);
		}
		watchScroll();
		// Remove listener (like componentWillUnmount)
		return () => {
			window.removeEventListener('scroll', logit);
		};
	}, []);

	return (
		<>
			<section className='subjectSection px-0 pt-[50px] pb-[50px] sm:pt-[80px] sm:pb-[80px] lg:pt-[100px] lg:pb-[100px]'>
				<div className='innerDiv zeroHorizontalPad'>
					<h2 className='text-black md:text-5xl text-3xl font-bold mb-16 pb-5 px-6'>Recommended for you</h2>
					<div className='listContainer'>
						<ul className='listContainerItem flex' style={{transform: 'translate3d( ' + parseInt(scrollY) / 100 + '%, 0%, 0)'}}>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
						</ul>
						<ul className='listContainerItem' style={{transform: 'translate3d( -' + parseInt(scrollY) / 100 + '%, 0%, 0)'}}>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
						</ul>
						<ul className='listContainerItem' style={{transform: 'translate3d( ' + parseInt(scrollY) / 100 + '%, 0%, 0)'}}>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
							<li className='relative mb-5'>
								<div className='relative mb-5'>
									<Image src={SubjectImage} width={170} height={190} alt='' />
									<div className='imgOverly'>
										<h4 className='text-white'>Math</h4>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</section>
		</>
	);
};
export default Subject;
